// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-shelf-js": () => import("./../src/pages/shelf.js" /* webpackChunkName: "component---src-pages-shelf-js" */),
  "component---src-pages-style-transfer-js": () => import("./../src/pages/style-transfer.js" /* webpackChunkName: "component---src-pages-style-transfer-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

